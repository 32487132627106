<template>
	<div class="page-container">

		<md-app md-waterfall md-mode="fixed-last">

			<!-- Toolbar -->
			<md-app-toolbar class="md-dense highz color-gray">
				
				<div class="md-toolbar-row">
					<!-- Logo and Title -->
					<div class="md-toolbar-section-start" style="padding: 0px;">
						<img
							id="imgLogo"
							alt="Honey & Moss Home Logo"
							src="../assets/honeyandmosshome_logo2021_circle_alpha.png"
							v-on:click="setNewTabIndex(1)"
							style="cursor: pointer;"
						/>
						<span class="md-title">Honey & Moss</span>
					</div>

					<!-- Right Menu -->
					<div class="md-toolbar-section-end">

						<!-- Admin Icon -->
						<div v-if="!isLoggedOut">
							<md-icon style="font-size: 24px; color: red; padding-right: 16px;">vpn_key</md-icon>
						</div>

						<!-- Social Media Links -->
						<md-button class="md-icon-button" md-menu-trigger onclick="window.open('https://instagram.com/honeyandmoss?igshid=1631v1m9hvlc')">
							<i class="icon icon-instagram" style="font-size: 24px"></i>
						</md-button>
						<md-button class="md-icon-button" md-menu-trigger onclick="window.open('https://www.pinterest.com/stefany_ovalles/h-o-m-e/')">
							<i class="icon icon-pinterest" style="font-size: 24px"></i>
						</md-button>

						<!-- Search -->
						<md-field style="max-width: 300px;">
							<label>Search</label>
							<md-input v-model="searchText_Content" @input="searchTextChanged_Content" ></md-input>
						</md-field>

						<!-- Admin / Extra Info -->
						<md-menu md-size="medium" md-align-trigger>
							<md-button class="md-icon-button" md-menu-trigger>
								<md-icon>more_vert</md-icon>
							</md-button>
							<md-menu-content>
								<md-menu-item>
									<md-button v-on:click="setNewTabIndex(10)">About Me</md-button>
								</md-menu-item>
								<md-menu-item>
									<md-button v-on:click="setNewTabIndex(9)">Contact Me</md-button>
								</md-menu-item>
								<md-menu-item v-if="isLoggedOut">
									<md-button v-on:click="setNewTabIndex(11)">Admin Login</md-button>
								</md-menu-item>
								<md-menu-item v-if="!isLoggedOut">
									<md-button v-on:click="logout()">Admin Logout</md-button>
								</md-menu-item>
							</md-menu-content>
						</md-menu>
					</div>
				</div>

				<!-- Tabs Display -->
				<div v-if="tabIndex !== 11">
					<div class="md-toolbar-row">
						<md-tabs class="md-accent">
							<md-tab id="tab-home" 		md-label="Our Home" 	v-on:click="setNewTabIndex(1)" ></md-tab>
							<md-tab id="tab-latinx"		md-label="Latinx" 		v-on:click="setNewTabIndex(2)" ></md-tab>
							<md-tab id="tab-jake"		md-label="Jake" 		v-on:click="setNewTabIndex(3)" ></md-tab>
							<md-tab id="tab-clientwork"	md-label="Client Work" 	v-on:click="setNewTabIndex(4)" ></md-tab>
							<div v-if="!isLoggedOut">
								<md-tab id="tab-admin-content" md-label="Posts" v-on:click="setNewTabIndex(12)"></md-tab>
							</div>
							<div v-if="!isLoggedOut">
								<md-tab id="tab-admin-images" md-label="Imgs" v-on:click="setNewTabIndex(13)"></md-tab>
							</div>
							<div v-if="!isLoggedOut">
								<md-tab id="tab-admin-logs" md-label="Logs" v-on:click="setNewTabIndex(14)"></md-tab>
							</div>
						</md-tabs>
					</div>
				</div>
			</md-app-toolbar>
			<br>

			<!-- Our Home -->
			<md-app-content v-if="tabIndex == 1">
				<br>
				<!-- Breadcrumbs -->
				<!-- 
				<Breadcrumb class="row justify-content-center mt-4" :crumbs="crumbs" @selected="selected" />
				-->

				<CardGroup :cardData="contentOurHome" :images="images"/>
			</md-app-content>

			<!-- Latinx -->
			<md-app-content v-if="tabIndex == 2">
				<br>
				<CardGroup :cardData="contentLatinx" :images="images"/>
			</md-app-content>

			<!-- Jake -->
			<md-app-content v-if="tabIndex == 3">
				<br>
				<CardGroup :cardData="contentJake" :images="images"/>
			</md-app-content>

			<!-- Client Work -->
			<md-app-content v-if="tabIndex == 4">
				<br>
				<CardGroup :cardData="contentClientWork" :images="images"/>
			</md-app-content>
			
			<!-- Contact Me -->
			<md-app-content v-if="tabIndex == 9">
				<br>
				<br>
				<br>
				<div style="max-width: 460px; margin: auto;">
					Contact Me
					<md-field>
						<label>Name</label>
						<md-input v-model="contactMe.name" required @input="contactMeChanged"></md-input>
					</md-field>
					<md-field>
						<label>Email</label>
						<md-input v-model="contactMe.email" type="email" required email @input="contactMeChanged"></md-input>
					</md-field>
					<md-field>
						<label>Subject</label>
						<md-input v-model="contactMe.subject" required @input="contactMeChanged"></md-input>
					</md-field>
					<md-field>
						<label>Message</label>
						<md-input v-model="contactMe.message" required @input="contactMeChanged"></md-input>
					</md-field>
					<span style="color: red;"> {{ contactMeMessages }} </span>
					<div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
						<span class="material-icons">email</span>
						<md-button class="md-primary md-raised" @click="contactMeSubmit" :disabled="isContactMeSubmitDisabled || isContactMeSubmitInProgress">Submit</md-button>
					</div>
					<div v-if="isContactMeSubmitInProgress === true">In Progress...</div>
					<div v-if="isContactMeSubmitResultMessage !== ''"> {{ isContactMeSubmitResultMessage }}</div>
				</div>
			</md-app-content>

			<!-- About Me -->
			<md-app-content v-if="tabIndex == 10">
				<br>
				<CardGroup :cardData="contentAboutMe" :images="images"/>
			</md-app-content>

			<!-- Admin Login  -->
			<md-app-content v-if="tabIndex == 11" class="simple-bg">
				<br>
				<div class="example">
					<div class="md-layout md-gutter" :class="`md-alignment-center-center`">
						<div class="md-layout-item md-size-100">
							
							<md-card class="md-primary rounded-card" md-theme="black-card" style="width: 340px; background-color: white; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);">
								<md-card-header>
									<md-card-header-text>
										<div class="md-title">Admin Login</div>
										<div>Environment: {{ app_mode }}</div>
									</md-card-header-text>
								</md-card-header>
							
								<md-card-content>
									<md-field md-clearable>
										<label>Email</label>
										<md-input v-model="email" style="max-width: 264px; background-color: white;"></md-input>
									</md-field>
									<md-field>
										<label>Password</label>
										<md-input v-model="password" type="password" style="max-width: 264px; background-color: white;"></md-input>
									</md-field>
								</md-card-content>
												
								<md-card-actions>
									<md-button v-on:click="login" class="md-accent">Login</md-button>
								</md-card-actions>
							</md-card>

						</div>
					</div>
				</div>
			</md-app-content>
			
			<!-- Admin View -->
			<md-app-content v-if="tabIndex == 12">
				<br>
				<div class="flex-container">
					<div class="md-card flex-item">

						<!-- md-card md-fixed-header -->
						<md-table v-model="searched" md-sort="name" md-sort-order="asc" @md-selected="onSelect">

							<md-table-toolbar>
								<div class="md-toolbar-section-start">
									<h1 class="md-title">Content</h1>
								</div>
								<md-field md-clearable class="md-toolbar-section-end">
									<md-input placeholder="Search by name..." v-model="search" @input="searchOnTable" />
								</md-field>
							</md-table-toolbar>
							
							<md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass()" md-selectable="single">
								<md-table-cell md-label="Name" md-sort-by="name"> {{ item.name }} </md-table-cell>
								<md-table-cell md-label="Title" md-sort-by="title"> {{ item.title }} </md-table-cell>
								<md-table-cell md-label="Creation Date" md-sort-by="creationDate"> {{ item.creationDate }} </md-table-cell>
								<md-table-cell md-label="Release Date" md-sort-by="releaseDate"> {{ item.releaseDate }} </md-table-cell>
								<md-table-cell md-label="Archive Date" md-sort-by="archiveDate"> {{ item.archiveDate }} </md-table-cell>
							</md-table-row>
							
							<!--
							<md-table-pagination
								:md-page-size="10"
								:md-page-options="[5,10,100,1000]"
							/>
							-->
						</md-table>
					</div>

					<!-- Show Content Data -->
					<!-- Use this for View and Edit Modes -->
					<!-- md-size-50 md-medium-hide --->
					<div class="md-card flex-item">

						<div v-if="!selectedContentEditable && !intentToCreate">
							<div style="display: flex; flex-direction: row; align-items: center; justify-content: start;">
								<span class="material-icons">add_circle</span>
								<md-button class="md-primary md-raised" @click="newContent">Create New Content</md-button>
							</div>
						</div>
						
						<!-- View Content or Edit Content -->
						<div v-if="selectedContentEditable">

							<!-- Header Block -->
							<div v-if="isEditDisabled === false" style="display: flex; flex-direction: row; align-items: center; justify-content: start;">
								<span class="material-icons">edit</span>
								<md-button class="md-primary md-raised" @click="toggleEditMode" style="width: 100px;">Edit Mode</md-button>
								<div v-if="hasUnsavedData === true">
									<md-button class="md-secondary md-raised" @click="saveData" style="width: 100px;">Save</md-button>
								</div>
								<div v-if="hasUnsavedData === false">
									<md-button class="md-secondary md-raised" disabled style="width: 100px;">Save</md-button>
								</div>
							</div>
							<div v-if="isEditDisabled === true" style="display: flex; flex-direction: row; align-items: center; justify-content: start;">
								<span class="material-icons">visibility</span>
								<md-button class="md-primary md-raised" @click="toggleEditMode" style="width: 100px;">View Mode</md-button>
							</div>

							<!-- Body Content -->
							<md-field>
								<label>Name</label>
								<md-input v-model="selectedContentEditable.name" :disabled="isEditDisabled" @input="contentEditChange"></md-input>
							</md-field>
							
							<md-field>
								<label>Title</label>
								<md-input v-model="selectedContentEditable.title" :disabled="isEditDisabled" @input="contentEditChange"></md-input>
							</md-field>
							
							<md-field>
								<label>Subtitle</label>
								<md-input v-model="selectedContentEditable.subtitle" :disabled="isEditDisabled" @input="contentEditChange"></md-input>
							</md-field>

							<md-field>
								<label>Description</label>
								<md-input v-model="selectedContentEditable.description" :disabled="isEditDisabled" @input="contentEditChange"></md-input>
							</md-field>

							<md-field>
								<label>Creation Date</label>
								<md-input v-model="selectedContentEditable.creationDate" :disabled="true"></md-input>
							</md-field>

							<div v-if="!isEditDisabled">
								<md-subheader>Release Date</md-subheader>
								<md-datepicker v-model="selectedContentEditable.releaseDate" @input="contentEditChange"></md-datepicker>
							</div>
							<div v-if="isEditDisabled">
								<md-field>
									<label>Release Date</label>
									<md-input v-model="selectedContentEditable.releaseDate" :disabled="true"></md-input>
								</md-field>
							</div>

							<div v-if="!isEditDisabled">
								<md-subheader>Archive Date</md-subheader>
								<md-datepicker v-model="selectedContentEditable.archiveDate" @input="contentEditChange"></md-datepicker>
							</div>
							<div v-if="isEditDisabled">
								<md-field>
									<label>Archive Date</label>
									<md-input v-model="selectedContentEditable.archiveDate" :disabled="true"></md-input>
								</md-field>
							</div>

							<br>
							<div v-if="!isEditDisabled">
								<md-chips v-model="selectedContentEditable.keywords" md-placeholder="Add Keywords..." @md-insert="contentEditChange" @md-delete="contentEditChange"></md-chips>
							</div>
							<div v-if="isEditDisabled" style="display: flex; align-items: center; justify-content: start;">
								<md-subheader style="width: 100px;">Keywords</md-subheader>
								<md-chip v-for="keyword in selectedContentEditable.keywords" :key="keyword">
									{{ keyword }}
								</md-chip>
							</div>
							<br>
							<div v-if="!isEditDisabled">
								<md-chips v-model="selectedContentEditable.sections" md-placeholder="Add Sections..." @md-insert="contentEditChange" @md-delete="contentEditChange"></md-chips>
							</div>
							<div v-if="isEditDisabled" style="display: flex; align-items: center; justify-content: start;">
								<md-subheader style="width: 100px;">Sections</md-subheader>
								<md-chip v-for="section in selectedContentEditable.sections" :key="section">
									{{ section }}
								</md-chip>
							</div>
							<br>

							<!--
							<md-field>
								<label>Image Source</label>
								<md-input v-model="selectedContentEditable.imageSource" :disabled="isEditDisabled" @input="contentEditChange"></md-input>
							</md-field>
							-->
									
							<md-autocomplete
								v-model="selectedContentEditable.imageSource"
								:md-options="imageList"
								md-layout="box"
								md-dense
								:disabled="isEditDisabled"
								@input="contentEditChange"
							>
								<label>Image Source</label>
							</md-autocomplete>
							
							<!-- File Upload Queue -->
							<md-field v-if="!isEditDisabled">
								<input type="file" accept="image/png, image/jpg, image/jpeg" @change="uploadImageRequest">
								<img id='imagePreview' style="max-width: 180px; max-height: 180px;">
							</md-field>
							
							<md-field>
								<label>Image Name</label>
								<md-input v-model="selectedContentEditable.imageName" :disabled="isEditDisabled" @input="contentEditChange"></md-input>
							</md-field>
							
							<md-field>
								<label>Pinterest Linkage</label>
								<md-input v-model="selectedContentEditable.pinterestLinkage" :disabled="isEditDisabled" @input="contentEditChange"></md-input>
							</md-field>
							
							<md-field>
								<label>Image Click Linkage</label>
								<md-input v-model="selectedContentEditable.imageClickLinkage" :disabled="isEditDisabled" @input="contentEditChange"></md-input>
							</md-field>
							
							<md-field>
								<label>Image Front Text</label>
								<md-input v-model="selectedContentEditable.imageFrontText" :disabled="isEditDisabled" @input="contentEditChange"></md-input>
							</md-field>

							<!-- Admin Data Viewing -->
							<div>
								<md-dialog :md-active.sync="showAdminViewEditDetails">
									<md-dialog-title>Admin Data</md-dialog-title>

									<md-tabs md-dynamic-height>
										<md-tab md-label="Database Data Block">
											<pre style="max-width: 40%; text-align: start;">{{selectedContent}}</pre>
										</md-tab>
										<md-tab md-label="Editable Data Block">
											<pre style="max-width: 40%; text-align: start;">{{selectedContentEditable}}</pre>
										</md-tab>
										<md-tab md-label="Activity">
											<p>Coming Soon</p>
										</md-tab>
									</md-tabs>

									<md-dialog-actions>
										<md-button class="md-primary" @click="showAdminViewEditDetails = false">Close</md-button>
									</md-dialog-actions>
								</md-dialog>
								<md-button class="md-primary md-raised" @click="showAdminViewEditDetails = true">Show Admin Data</md-button>
							</div>
						</div>

						<!-- New Content Mode --->
						<div v-if="!selectedContentEditable && intentToCreate">
							
							<!-- Header Block -->
							<div v-if="isValidCreateContentData === true && insertContent_RequestInProgress === false" style="display: flex; flex-direction: row; align-items: center; justify-content: start;">
								<span class="material-icons">add_circle</span>
								<md-button class="md-primary md-raised" @click="createContent" style="width: 100px;"> Create </md-button>
							</div>
							<div v-if="isValidCreateContentData === false || insertContent_RequestInProgress === true" style="display: flex; flex-direction: row; align-items: center; justify-content: start;">
								<span class="material-icons">add_circle</span>
								<md-button class="md-primary md-raised" disabled style="width: 100px;"> Create </md-button>
							</div>

							<!-- Body Content -->
							<md-field>
								<label>Name</label>
								<md-input v-model="createdContentEditable.name" @input="contentCreateChange"></md-input>
							</md-field>
							
							<md-field>
								<label>Title</label>
								<md-input v-model="createdContentEditable.title" @input="contentCreateChange"></md-input>
							</md-field>
							
							<md-field>
								<label>Subtitle</label>
								<md-input v-model="createdContentEditable.subtitle" @input="contentCreateChange"></md-input>
							</md-field>

							<md-field>
								<label>Description</label>
								<md-input v-model="createdContentEditable.description" @input="contentCreateChange"></md-input>
							</md-field>

							<md-field>
								<label>Creation Date</label>
								<md-input v-model="createdContentEditable.creationDate" :disabled="true"></md-input>
							</md-field>

							<div>
								<md-subheader>Release Date</md-subheader>
								<md-datepicker v-model="createdContentEditable.releaseDate" @input="contentCreateChange"></md-datepicker>
							</div>

							<div>
								<md-subheader>Archive Date</md-subheader>
								<md-datepicker v-model="createdContentEditable.archiveDate" @input="contentCreateChange"></md-datepicker>
							</div>
							<br>

							<div>
								<md-chips v-model="createdContentEditable.keywords" md-placeholder="Add Keywords..." @md-insert="contentCreateChange" @md-delete="contentEditChange"></md-chips>
							</div>
							<br>

							<div>
								<md-chips v-model="createdContentEditable.sections" md-placeholder="Add Sections..." @md-insert="contentCreateChange" @md-delete="contentEditChange"></md-chips>
							</div>
							<br>

							<!--
							<md-field>
								<label>Image Source</label>
								<md-input v-model="createdContentEditable.imageSource" @input="contentCreateChange"></md-input>
							</md-field>
							-->
							
							<md-autocomplete
								v-model="createdContentEditable.imageSource"
								:md-options="imageList"
								md-layout="box"
								md-dense
								@input="contentCreateChange"
							>
								<label>Image Source</label>
							</md-autocomplete>
							
							<md-field>
								<label>Image Name</label>
								<md-input v-model="createdContentEditable.imageName" @input="contentCreateChange"></md-input>
							</md-field>
							
							<md-field>
								<label>Pinterest Linkage</label>
								<md-input v-model="createdContentEditable.pinterestLinkage" @input="contentCreateChange"></md-input>
							</md-field>
							
							<md-field>
								<label>Image Click Linkage</label>
								<md-input v-model="createdContentEditable.imageClickLinkage" @input="contentCreateChange"></md-input>
							</md-field>
							
							<md-field>
								<label>Image Front Text</label>
								<md-input v-model="createdContentEditable.imageFrontText" @input="contentCreateChange"></md-input>
							</md-field>

							<!-- Admin Data Viewing -->
							<div>
								<md-dialog :md-active.sync="showAdminCreateDetails">
									<md-dialog-title>Admin Data</md-dialog-title>

									<md-tabs md-dynamic-height>
										<md-tab md-label="Created Data Block">
											<pre style="max-width: 40%; text-align: start;">{{createdContentEditable}}</pre>
										</md-tab>
										<md-tab md-label="Template Example">
											<p>Coming Soon</p>
										</md-tab>
									</md-tabs>

									<md-dialog-actions>
										<md-button class="md-primary" @click="showAdminCreateDetails = false">Close</md-button>
									</md-dialog-actions>
								</md-dialog>
								<md-button class="md-primary md-raised" @click="showAdminCreateDetails = true">Show Admin Data</md-button>
							</div>
						</div>
					</div>
				</div>
			</md-app-content>

			<!-- Admin Images View -->
			<md-app-content v-if="tabIndex == 13">
				
				<div style="width: 100%; padding-top: 50px;">
					Admin Images View
				</div>
				<br>

				<!-- File Upload Queue -->
				<div>
					<input type="file" accept="image/png, image/jpg, image/jpeg" @change="uploadImageRequest">
					<img id='imagePreview' style="max-width: 180px; max-height: 180px;">
				</div>
				<br>

				<!-- All Images -->
				<div style="display: flex; align-items: center; justify-content: start; width: 100%; padding-top: 16px;">
					<div style="width: 100%;">
						<md-chip v-for="image in imageList" :key="image">
							{{ image }}
						</md-chip>
					</div>
				</div>

				<!--
				selectedContentEditable.imageSource
				contentEditChange
				<div v-if="isEditDisabled" style="display: flex; align-items: center; justify-content: start;">
					<md-subheader style="width: 100px;">Keywords</md-subheader>
					<md-chip v-for="keyword in selectedContentEditable.keywords" :key="keyword">
						{{ keyword }}
					</md-chip>
				</div>
					<div v-for="image in imageList" :key="image">
						{{ image }}
					</div>
				-->

			</md-app-content>

			<!-- Admin Log View -->
			<md-app-content v-if="tabIndex == 14">
				<div style="width: 100%; padding-top: 50px;">
					Admin Log View (Coming Soon!)
				</div>
			</md-app-content>

		</md-app>
		
	</div>
</template>

<style lang="scss" scoped>

	/*
	.md-tab {
		font-size: 8px;
	}
	*/

	.md-tooltip {
		left: 100px;
	}

	.color-gray {
		color: "gray";
	}

	.md-app {
		height: 100vh;
		border: 1px solid rgba(#000, 0.12);
	}

	.md-drawer {
		width: 230px;
		max-width: calc(100vw - 125px);
	}
	
	.-striped-bgs-two {
		background-image: linear-gradient(134deg, rgba(240,240,240,.5) 50%, transparent 0),
							linear-gradient(-134deg, rgba(240,240,240,.5) 50%, transparent 0);
		background-size: 25px 25px;
	}

	.highz {
		z-index: 99999;
	}

	.simple-bg {
		background-color: rgb(240,240,240);
	}

	.rounded-card{
		border-radius: 12px;
	}

	.flex-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		.flex-item {
			flex: auto;
			padding: 12px;
			margin: 12px;
			width: 400px;
		}
	}
</style>

<!--
	Colors
	/// Background Color - white rgb(255,255,255)
	/// 1st Top bar Color - #F0F4F7 rgb(240,244,247)
-->

<script>
	import Vue from "vue";
	import VueMaterial from "vue-material";
	import "vue-material/dist/vue-material.min.css";
	import CardGroup from "./CardGroup.vue";
	//import Breadcrumb from './Breadcrumb.vue';

	Vue.use(VueMaterial);

	const toLower = text => {
		return text.toString().toLowerCase()
	}

	const searchByName = (items, term) => {
		if (term) {
			return items.filter(item => toLower(item.name).includes(toLower(term)))
		}
		return items
	}


	async function postData(url = '', data = {}) {
		// Default options are marked with *
		const response = await fetch(url, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json'
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
			body: JSON.stringify(data) // body data type must match "Content-Type" header
		});
		return response.json(); // parses JSON response into native JavaScript objects
	}

	export default {
		name: "Reveal1",
		props: [
			'contentOurHome',
			'contentLatinx',
			'contentJake',
			'contentClientWork',
			'contentAboutMe',
			'refreshContent',
			'forceTabChange',
			'images'
		],
		data: () => ({
			searchText_Content				: "",
			tabIndex				 		: 1,
			activeTab                		: null,
			email					 		: '',
			password				 		: '',
			loginKey				 		: {}, 		/// { key: string, expiration: date?string }
			search					 		: null,
			searched				 		: [],
			selectedContent			 		: null, 	/// null when empty, or { ... } when not-empty
			selectedContentEditable	 		: null, 	/// Used to properly control the flow of changes
			intentToCreate           		: false,
			isValidCreateContentData 		: true,
			createdContentEditable   		: null, 	/// Used to isolate the "new object" creation
			insertContent_RequestInProgress : false,
			contentArray			 		: [], 		/// testUserData
			imageList                		: [], 		/// actual list from server (if we have access)
			contentTemplate          		: {},
			isEditDisabled			 		: true, 	/// default = true (view mode only)
			showAdminViewEditDetails 		: false, 	/// this shows data block (admin)
			showAdminCreateDetails   		: false,
			hasUnsavedData			 		: false,
			content_name			 		: "",
			content_title			 		: "",
			isLoggedOut				 		: true,
			singleImageUpload        		: null,
			isContactMeSubmitDisabled		: true,
			isContactMeSubmitInProgress		: false,
			isContactMeSubmitResultMessage	: "",
			contactMeMessages				: "",
			contactMe						: { name: "", email: "", subject: "", message: "" },
			crumbs							: ['Home', 'Category', 'Subcategory'],
			app_mode						: process.env.NODE_ENV
		}),
        methods: {
			selected(crumb) {
				console.log(crumb);
			},
			searchTextChanged_Content: function(){
				//console.log("New search text:", this.searchText_Content);
				
				let index = this.tabIndex;
				let sectionName = "";
				switch(index){
					case 1  : sectionName = "Our Home";    break;
					case 2  : sectionName = "Latinx";      break;
					case 3  : sectionName = "Jake";        break;
					case 4  : sectionName = "Client Work"; break;
					case 10 : sectionName = "About Me";    break;
				}
				if(sectionName !== "" ){
					let searchStringFinal = "";
					if(index !== 10){ searchStringFinal = this.searchText_Content; } // Ignore About Me searching...
					this.refreshContent(sectionName, searchStringFinal);
				}
			},
			contentCreateChange: function(){
				//console.log("Content create element has changed!");
			},
			uploadImageRequest: function(event){
				let scope = this;

				console.log("Image Upload Requested.");

				const file = event.target.files[0];
				console.log("file (event.target.files[0])", file);
				
				let reader = new FileReader();
				reader.onload = function(){

					let base64data = reader.result; // data:image/png;base64,iVBORw0KGgoAAAANSUh...
					if(base64data instanceof ArrayBuffer){
						console.error("Error, can not handle ArrayBuffers for image upload.");
					}else{
						/// Get Base64 String and Set Preview Object
						let previewHTML = document.getElementById('imagePreview');
						previewHTML.src = base64data;

						console.log("as data URL:", base64data.substring(0, 100));

						/// Prepare Data Packet
						let key = localStorage.getItem('key');
						let dataPacket = {
							authenticationKey : key,
							data: base64data,
							fileName: file.name,
							fileSize: file.size,
							fileType: file.type
						};

						console.log("Outgoing packet for image upload:", dataPacket);

						const originToUse = scope.getOriginToUse();
						const postURL = originToUse + '/image';

						postData(postURL, dataPacket)
							.then(data => {
								console.log(data); // JSON data parsed by `data.json()` call
							})
							.catch(error => {
								console.error(error);
							});
							
					}
				};
				reader.readAsDataURL(file);
			},
			contactMeChanged: function(){
				let contactMeBlock = this.contactMe;

				/// Check if we should keep disabled, if not, explain why
				let contactMeMessages = "";
				if(contactMeBlock.name === ""){
					if(contactMeMessages !== ""){ contactMeMessages += ", "; } contactMeMessages += "Name is Blank";
				}
				if(contactMeBlock.email === ""){
					if(contactMeMessages !== ""){ contactMeMessages += ", "; } contactMeMessages += "Email is Blank";
				}else{
					if(
						contactMeBlock.email.includes("@") === false ||
						contactMeBlock.email.includes(".") === false ||
						contactMeBlock.email.includes(" ") === true
					){
						if(contactMeMessages !== ""){ contactMeMessages += ", "; } contactMeMessages += "Email is Invalid";
					}
				}
				if(contactMeBlock.subject === ""){
					if(contactMeMessages !== ""){ contactMeMessages += ", "; } contactMeMessages += "Subject is Blank";
				}
				if(contactMeBlock.message === ""){
					if(contactMeMessages !== ""){ contactMeMessages += ", "; } contactMeMessages += "Message is Blank";
				}
				this.contactMeMessages = contactMeMessages;
				
				let isContactMeSubmitDisabled = true;
				if(this.contactMeMessages === ""){ isContactMeSubmitDisabled = false; }
				this.isContactMeSubmitDisabled = isContactMeSubmitDisabled;
			},
			contentEditChange: function(){

				let originalContent    = this.selectedContent;
				let editedContent      = this.selectedContentEditable;
				let detectedDifference = false;

				try{
					let originalContent_string = JSON.stringify(originalContent);
					let editedContent_string   = JSON.stringify(editedContent);
					if(originalContent_string.length === editedContent_string.length){
						/// Check every character
						for(let i = 0; i < originalContent_string.length; i = i + 1){
							let char_original = originalContent_string[i];
							let char_edited = editedContent_string[i];
							if(char_original !== char_edited){
								detectedDifference = true;
								i = originalContent_string.length;
							}
						}
					}else{
						/// Difference...
						detectedDifference = true;
					}

					//console.log("Difference check result: " + detectedDifference);
					this.hasUnsavedData = detectedDifference;
				}catch(error){
					console.error("Error with comparing objects.");
				}
			},
			getClass: () => ({
				'md-primary': true
			}),
			onSelect (item) {
				this.intentToCreate          = false;
				this.selectedContent         = item;
				this.selectedContentEditable = this.cloneContent(item);
				this.hasUnsavedData          = false;
				this.getImagePool();
			},
			logout: function(){
				this.setNewTabIndex(1);
				localStorage.setItem("key", "");
				localStorage.setItem("expiration", "");
				this.isLoggedOut = true;
			},
            setNewTabIndex: function (index) {

				let sectionName = "";
				switch(index){
					case 1  : sectionName = "Our Home";    break;
					case 2  : sectionName = "Latinx";      break;
					case 3  : sectionName = "Jake";        break;
					case 4  : sectionName = "Client Work"; break;
					case 10 : sectionName = "About Me";    break;
				}
				if(this.tabIndex !== index && sectionName !== "" ){
					let searchStringFinal = "";
					if(index !== 10){ searchStringFinal = this.searchText_Content; } // Ignore About Me searching...
					this.refreshContent(sectionName, searchStringFinal);
				}

                this.tabIndex = index;
				this.forceTabChange(index);
				localStorage.setItem('lastTab', this.tabIndex);
				//console.log("Setting last tab index to: ", this.tabIndex);

				if(index === 13){
					//console.log("Index for get image pool", index);
					this.getImagePool();
				}
            },
			generateQueryString(queryStrings){
				let queryRunningString = "?";
				for(let i = 0; i < queryStrings.length; i = i + 1){
					queryRunningString += queryStrings[i];
					if(i + 1 < queryStrings.length){ queryRunningString += "&"; }
				}
				return queryRunningString;
			},
			updatePagination (page, pageSize, sort, sortOrder) {
				console.log('pagination has updated', page, pageSize, sort, sortOrder);
			},
			getOriginToUse: function(){
				/// Get Server Origin to Contact
				let overrideOrigin = true;
				if(process.env.NODE_ENV === "production"){
					overrideOrigin = false;
				}else if(process.env.NODE_ENV === "dev"){
					overrideOrigin = true;
				}else{
					console.log("(!) Error, unhandled environment: " + process.env.NODE_ENV);
				}
				let originToUse = "http://localhost:3000";
				if(overrideOrigin === true){
					/// console.log("Danger, origin to use has been overridden to be " + originToUse + " (change this for production).");
				}else{
					originToUse = window.location.origin;
				}
				/// console.log("Getting origin to use based on NODE_ENV:", process.env.NODE_ENV, originToUse);
				return originToUse;
			},
			getImagePool: function(){
				let scope = this;

				if(!scope.loginKey.key){
					console.warn("Ignoring image pool request (no access key found).");
				}else{
					let originToUse = scope.getOriginToUse();
					let queryRunningString = scope.generateQueryString([
						"key=" + scope.loginKey.key
					]);
					
					let endpoint = originToUse + "/pool" + queryRunningString;
					///console.log("Requesting get image pool.", endpoint);

					/// Send request with key
					fetch(endpoint)
						.then((res) => res.json())
						.then((data) => {
							/// console.log("Pool Data Return", data);
							scope.imageList = data.imageList;
						})
						.catch((error) => {
							console.log(error);
						});
				}
			},
			adminPage_init: function(){
				let scope = this;

				let originToUse = scope.getOriginToUse();
				let queryRunningString = scope.generateQueryString([
					"key=" + scope.loginKey.key,
				]);
				
				/// Send request with key
				fetch(originToUse + "/admin" + queryRunningString)
					.then((res) => res.json())
					.then((data) => {
						// console.log("Admin Data Return", data);
						scope.contentArray    = data.contentArray;
						scope.contentTemplate = data.contentTemplate;
						scope.searched        = this.contentArray;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			login: function () {
				let scope = this;
				let key = localStorage.getItem('key');
				//let expiration = localStorage.getItem('expiration');
				//console.log("Login requested: ", scope.email, scope.password, key);
				if(
					(scope.email !== "" && scope.password !== "") ||
					key !== ""
				){
						
					let originToUse = scope.getOriginToUse();
					let queryRunningString = scope.generateQueryString([
						"email=" + scope.email,
						"password=" + scope.password,
						"key=" + key
					]);

					fetch(originToUse + "/login" + queryRunningString)
						.then((res) => res.json())
						.then((data) => {
							// console.log("Login Data", data);
							if(data.success === true){

								scope.loginKey = data.loginKey;
								localStorage.setItem('key', data.loginKey.key);
								localStorage.setItem('expiration', data.loginKey.expiration);
								scope.isLoggedOut = false;
								//console.log("Logging in with loginKey: ", scope.loginKey.key, scope.loginKey.expiration);

								/// If on login screen, change
								if(scope.tabIndex == 11){
									scope.setNewTabIndex(12);
								}

								/// If we are on image tab, pull image pool
								if(scope.tabIndex === 13){
									this.getImagePool();
								}

								/// Init the admin page
								scope.adminPage_init();
							}else{
								/// Failed (Give feedback error)
							}
						})
						.catch((error) => {
							console.log(error);
						});
				}
			},
			contactMeSubmit () {
				let scope = this;
				scope.isContactMeSubmitResultMessage  = "";
				scope.isContactMeSubmitInProgress = true;
				console.log("Contact Me Submit");
				/// console.log(scope.contactMe);
				let contactMeName = scope.contactMe.name;
				let contactMeEmail = scope.contactMe.email;
				let contactMeSubject = scope.contactMe.subject;
				let contactMeMessage = scope.contactMe.message;
				let dataPacket = {
					name: contactMeName,
					email: contactMeEmail,
					subject: contactMeSubject,
					message: contactMeMessage,
				};
				const dataPacketString = JSON.stringify(dataPacket);
				let originToUse = scope.getOriginToUse();
				fetch(originToUse + "/contact", {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: dataPacketString,
					})
					.then(response => response.json())
					.then(responseBlock => {
						console.log('Insert Response Block:', responseBlock);
						scope.contactMe.name = "";
						scope.contactMe.email = "";
						scope.isContactMeSubmitResultMessage = "Successfully Sent " + (new Date()).toLocaleString();
						setTimeout(function(){ scope.isContactMeSubmitInProgress = false; }, 1000);
					})
					.catch((error) => {
						console.error('Error:', error);
						scope.isContactMeSubmitResultMessage = error;
						setTimeout(function(){ scope.isContactMeSubmitInProgress = false; }, 1000);
					});
			},
			newContent () {
				console.log("Generate New Content Block");
				this.intentToCreate = true;

				/// Build Default Block
				this.createdContentEditable = {};
				for(let key in this.contentTemplate){
					let dataBlock = this.contentTemplate[key];
					let type = dataBlock.type;
					let defaultValue = dataBlock.defaultValue;
					if(defaultValue){
						this.createdContentEditable[key] = defaultValue;
					}else{
						if(type === "string"){
							this.createdContentEditable[key] = "";
						}else if(type === "string[]"){
							this.createdContentEditable[key] = [];
						}else if(type === "date"){
							this.createdContentEditable[key] = (new Date()).toLocaleDateString();
						}else{
							/// Unhandled
							console.error("Unhandled template data type: " + type + " for key " + key);
						}
					}
				}
				
				/// Override the creationDate (it is overridden on insert anyway)
				this.createdContentEditable["creationDate"] = (new Date()).toLocaleDateString();
			},
			toggleEditMode(){
				this.isEditDisabled = !this.isEditDisabled;
				//console.log("Changing edit mode to: " + this.isEditDisabled);
			},
			searchOnTable () {
				this.intentToCreate          = false;
				this.selectedContent         = null;
				this.selectedContentEditable = null;
				this.hasUnsavedData          = false;
				this.searched                = searchByName(this.contentArray, this.search);
			},
			cloneContent(originalContent){
				let clonedContent = originalContent;
				try{
					clonedContent = JSON.parse(JSON.stringify(originalContent));
				}catch(error){
					console.error("Failed to clone content, error");
				}
				return clonedContent;
			},
			createContent(){

				/// Create Content request...
				console.log("Create Content Request using: ", this.createdContentEditable);
				
				let scope = this;
				let createItem = this.createdContentEditable;
				this.createdContentEditable = this.cloneContent(createItem);
				this.insertContent_RequestInProgress = true;
				let parsedInsertItem = JSON.parse(JSON.stringify(createItem));

				console.log("SENDING INSERT:", parsedInsertItem);

				/// Get Packet Together
				let key = localStorage.getItem('key');
				//let expiration = localStorage.getItem('expiration');
				const dataPacket = {
					authenticationKey	: key,
					type                : "Insert",
					data				: parsedInsertItem
				};
				const dataPacketString = JSON.stringify(dataPacket);

				let originToUse = scope.getOriginToUse();
				let endpoint = originToUse + "/content";
				fetch(endpoint, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: dataPacketString,
					})
					.then(response => response.json())
					.then(responseBlock => {
						console.log('Insert Response Block:', responseBlock);
						let responseStatus = responseBlock.status;
						let responseContent = responseBlock.content;

						if(responseStatus === true){
							scope.contentArray.push(responseContent);
							scope.intentToCreate = false;
						}else{
							console.error("Error, insert failed.");
						}

						scope.insertContent_RequestInProgress = false;
					})
					.catch((error) => {
						console.error('Error:', error);
						console.error('Endpoint:' + endpoint);
						console.error('Outgoing Data:', dataPacketString);
						scope.insertContent_RequestInProgress = false;
					});
				
			},
			saveData(){
				
				let scope = this;
				let saveItem = this.selectedContentEditable;
				let saveItemCloned = this.cloneContent(saveItem);
				for(let key in this.selectedContent){
					this.selectedContent[key] = saveItemCloned[key];
				}
				this.selectedContentEditable = this.cloneContent(saveItem);
				this.hasUnsavedData = false;

				let parsedSaveItem = JSON.parse(JSON.stringify(saveItem));

				/// Get Packet Together
				let key = localStorage.getItem('key');
				//let expiration = localStorage.getItem('expiration');
				const dataPacket = {
					authenticationKey	: key,
					type                : "Update",
					data				: parsedSaveItem
				};
				const dataPacketString = JSON.stringify(dataPacket);

				let originToUse = scope.getOriginToUse();
				let endpoint = originToUse + "/content";
				fetch(endpoint, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: dataPacketString,
					})
					.then(response => response.json())
					.then(responseBlock => {
						console.log('Update Response Block:', responseBlock);
						let responseStatus = responseBlock.status;
						let responseContent = responseBlock.content;

						function findAndReplace(content){
							if(content._id === responseContent._id){
								console.log("Found local copy of save item.");
								content = responseContent;
							}
						}

						if(responseStatus === true){
							/// Modify local copies
							scope.contentArray.forEach(findAndReplace);
							scope.searched.forEach(findAndReplace);
						}else{
							console.error("Error, update failed.");
						}

					})
					.catch((error) => {
						console.error('Error:', error);
						console.error('Endpoint:' + endpoint);
						console.error('Outgoing Data:', dataPacketString);
					});
				
			}
        },
		created () {
			let scope = this;
			scope.searched = scope.contentArray;
			scope.login();

			/// Load Routes
			let lastTab = localStorage.getItem('lastTab');
			//console.log("Loaded lastTab?", lastTab);
			if(lastTab !== null && lastTab !== undefined){
				//console.log("Valid lastTab found: ", lastTab);
				scope.setNewTabIndex(1.0 * lastTab);
			}else{
				/// Set lastTab to home
				localStorage.setItem('lastTab', 1);
				scope.setNewTabIndex(1);
			}
		},
		components: {
			CardGroup,
			//Breadcrumb
		},
	};
</script>