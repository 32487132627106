<template>
	<div id="app">
		<link rel="stylesheet" href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons">
		<Reveal1 
			:contentOurHome="contentOurHome"
			:contentLatinx="contentLatinx"
			:contentJake="contentJake"
			:contentClientWork="contentClientWork"
			:contentAboutMe="contentAboutMe"
			:refreshContent="refreshContent"
			:forceTabChange="forceTabChange"
			:images="images"
		/>
	</div>
</template>

<script>

	import Reveal1 from './components/Reveal1.vue';
	
	export default {
		name: 'App',
		data: function(){ return {
			contentOurHome: [],
			contentLatinx: [],
			contentJake: [],
			contentClientWork: [],
			contentAboutMe: [],
			images: {},
			searchString: "", /// Globally (per Section or entire site)

			contentSearch: [],
			originToUse: null,
			currentTab: 0,
		}},
		components: {
			Reveal1,
		},
		sockets: {
			connect: function () {
				//console.log('socket to notification channel connected');
			},
		},
		methods: {
			getImage2(imageSource, callback){
				let scope = this;
				let originToUse = scope.$data.originToUse;
				if(
					scope.$data.images[imageSource] === undefined
					//&& scope.$data.imagesState[imageSource] === undefined
				){
					//scope.$data.imagesState[imageSource] = "Loading";
					//scope.$data.images[imageSource] = null; /// This means it is IN QUEUE
					/// console.log("Get Image Called for imageSource: " + imageSource);
					fetch(originToUse + "/images" + "?imageSource=" + imageSource)
						.then((res) => res.json())
						.then((data) => {
							//console.log("Data", data);
							if(scope.$data.images[imageSource] === undefined){
								scope.$data.images[imageSource] = data.src;
								//scope.$data.imagesState[imageSource] = "Loaded";
							}else{
								/// Double requested, remove a request that must have been added
							}
							callback(scope.$data.images[imageSource]);
						})
						.catch((error) => {
							console.log(error);
						});
				}else{
					/// Ignore, no extra requested or loaded
					/// console.log("Get CACHED Image Called for imageSource: " + imageSource);
					callback(scope.$data.images[imageSource]);
				}
			},
			generateQueryString(queryStrings){
				let queryRunningString = "?";
				for(let i = 0; i < queryStrings.length; i = i + 1){
					queryRunningString += queryStrings[i];
					if(i + 1 < queryStrings.length){ queryRunningString += "&"; }
				}
				return queryRunningString;
			},
			getSectionWithQueryParameters(params){
				let scope = this;

				/// Get Server Origin to Contact
				let overrideOrigin = false;
				if(process.env.NODE_ENV === "production"){
					overrideOrigin = false;
				}else if(process.env.NODE_ENV === "dev"){
					overrideOrigin = true;
				}else{
					console.log("(!) Error, unhandled environment: " + process.env.NODE_ENV);
				}
				let originToUse = "http://localhost:3000";
				if(overrideOrigin === true){
					/// console.log("Danger, origin to use has been overridden to be " + originToUse + " (change this for production).");
				}else{
					originToUse = window.location.origin;
				}
				scope.$data.originToUse = originToUse;

				/// Get Initial Block of Data
				let startDaysAgo = params.startDaysAgo !== undefined ? params.startDaysAgo : 6000;
				let endDaysAgo = params.endDaysAgo !== undefined ? params.endDaysAgo : -5;
				let startDateWithDashes = new Date(); startDateWithDashes.setDate(startDateWithDashes.getDate() - startDaysAgo);
				let endDateWithDashes = new Date(); endDateWithDashes.setDate(endDateWithDashes.getDate() - endDaysAgo);
				let startDateQuery = "startDate=" + startDateWithDashes.toLocaleDateString().replace(/\//g, '-');
				let endDateQuery = "endDate=" + endDateWithDashes.toLocaleDateString().replace(/\//g, '-');
				let sectionQuery = params.section !== undefined ? "section=" + params.section : "section=";
				let searchStringQuery = params.searchString !== undefined ? "searchString=" + params.searchString : "searchString=";

				let queryGroup = [];
				queryGroup.push(startDateQuery);
				queryGroup.push(endDateQuery);
				queryGroup.push(sectionQuery);
				queryGroup.push(searchStringQuery);

				//console.log("*** sectionQuery", sectionQuery);

				let queryRunningString = this.generateQueryString(queryGroup);
				//console.log(queryRunningString);

				let endpoint = originToUse + "/content" + queryRunningString;
				fetch(endpoint)
					.then((res) => res.json())
					.then((data) => {
						
						scope.$data.contentOurHome = [];
						scope.$data.contentLatinx = [];
						scope.$data.contentJake = [];
						scope.$data.contentClientWork = [];
						scope.$data.contentAboutMe = [];
						scope.$data.contentSearch = [];
						if(data.length !== undefined && data.length > 0){
							for(let i = 0; i < data.length; i = i + 1){
								let element = data[i];
								if(element.imageSource !== ""){
									scope.getImage2(element.imageSource, function(){
										if(element.sections.includes("Our Home")){ scope.$data.contentOurHome.push(element); }
										if(element.sections.includes("Latinx")){ scope.$data.contentLatinx.push(element); }
										if(element.sections.includes("Jake")){ scope.$data.contentJake.push(element); }
										if(element.sections.includes("Client Work")){ scope.$data.contentClientWork.push(element); }
										if(element.sections.includes("About Me")){ scope.$data.contentAboutMe.push(element); }
										if(element.sections.includes("Search")){ scope.$data.contentSearch.push(element); }
										//scope.$data.content.push(element);
									});
								}else{
									//scope.$data.content.push(element);
									if(element.sections.includes("Our Home")){ scope.$data.contentOurHome.push(element); }
									if(element.sections.includes("Latinx")){ scope.$data.contentLatinx.push(element); }
									if(element.sections.includes("Jake")){ scope.$data.contentJake.push(element); }
									if(element.sections.includes("Client Work")){ scope.$data.contentClientWork.push(element); }
									if(element.sections.includes("About Me")){ scope.$data.contentAboutMe.push(element); }
									if(element.sections.includes("Search")){ scope.$data.contentSearch.push(element); }
								}
							}
						}
						/// This data needs to be used to setup all of the page content
						/// Images should be fetched individually on-demand (no pre-fetch)

						//console.log("raw data: ", data);
						//console.log("scape.$data: ", scope.$data);
					})
					.catch((error) => {
						console.error('Error:', error);
						console.error('Endpoint:' + endpoint);
					});
			},
			refreshContent: function(sectionName, searchString){
				//let sectionName = "Our Home";
				if(sectionName !== ""){
					//console.log("Refresh content query requested to tab index:", tabIndex, sectionName);
					//console.log(this);
					//let finalSearchString = this.$data.searchString !== "" ? this.$data.searchString : undefined;
					this.getSectionWithQueryParameters({ section: sectionName, searchString: searchString });
				}else{
					//console.log("Ignoring content refresh.", tabIndex, sectionName);
				}
			},
			forceTabChange: function(tabIndex){
				if(tabIndex < 0){
					console.log("Force tab change:", tabIndex);
				}
			}
		},
		mounted: function(){
			//console.log("MOUNTED");
			console.log("Honey & Moss Client Startup Finished");

			let sectionName = "Our Home";
			let finalSearchString = this.$data.searchString !== "" ? this.$data.searchString : undefined;
			this.getSectionWithQueryParameters({ section: sectionName, searchString: finalSearchString });
		}
	}
</script>

<style lang="scss">

	@import 'assets/scss/modules/_icon.scss'; /// Custom font (icoMoon made)
	@import "~vue-material/dist/theme/engine"; // Import the theme engine
	@include md-register-theme("default", (
		//primary: md-get-palette-color(grey, 100),		// The primary color of your application
		//accent: md-get-palette-color(blue, 800),		// The accent or secondary color
		//background: md-get-palette-color(grey, 500),
		accent: md-get-palette-color(grey, 100),		// The accent or secondary color
		theme: light
	));
	@import "~vue-material/dist/theme/all"; // Apply the theme

	.Reveal1 {
		height: 100%;
	}

	#search {
		background-color: rgb(240,244,247);
		margin: 4px;
		color: black;
		outline: 0;
		border: none;
		width: 90px;
		font-size: 12px;
		font-family: "Nunito";
		border-bottom: 1px solid rgb(64,64,64);
	}

	#search:focus {
		border-bottom: 2px solid rgb(0,0,0);
	}

	.item {
		margin: 10px 0px;
	}

	.flex-container {
		display: flex;
		flex-direction: column;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		margin-top: 0px;
		//padding-left: 100px;
		//padding-right: 100px;
	}

	body {
		margin: 0px 0px;

		/* Entire Website Background Color */
		background-color: rgb(255,255,255);
	}

	.navigation {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		align-content: center;
		
		/* Navigation Bar Background Color */
		background-color: rgb(240,244,247);
	}

	#left-group {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		align-content: center;
	}

	#right-group {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		align-content: center;
	}

	#middle-group {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		align-content: center;
	}

	button {
		margin: 10px 10px 10px 10px;
	}

	#imgLogo {
		width: 80px;
		height: 80px;
		object-fit: cover;
	}

	#imgGroup {
		width: calc(50% - 30px); /*calc(40% - 60px);*/
		margin: 12px;
		object-fit: cover;
	}

	.imgCover{
		position:relative;
	}
	
	.imgCover .innerBox{
		position:absolute;
		background:rgba(255,255,255,0.7);
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		padding:35px;
		text-align:center;

		opacity: 0.75;
		transition: 0.2s;
		cursor: pointer;
	}

	.imgCover .innerPin{
		position:absolute;
		top:calc(100% - 20px);
		left:50%;
		transform:translate(-50%,-100%);
		padding:4px;
		text-align:center;

		opacity: 0.75;
		transition: 0.2s;
		cursor: pointer;
	}

	.imgCover .innerBox:hover {
		opacity: 1;
	}

	.imgCover .innerPin:hover {
		opacity: 1;
	}

	.imgCover .inner_box h2{
		font-family:arial;
		text-align:center;
		font-size:26px;
		font-weight:normal;
		color:#777;
	}

	.imgCover .inner_box button{
		background:#aaa;
		color:#fff;
		text-transform:uppercase;
		font-weight:bold;
		font-size:16px;
		border:none;
		padding:10px 30px;
	}

	.social {
		padding-right: 4px;
		font-weight: bold;
		outline: 0;
		color: rgb(180,180,180);
		cursor: pointer;
	}

	.social:hover {
		color: black;
		outline: 0;
	}
	
	.social:focus {
		color: black;
		outline: 1;
	}

	.fs16 { font-size: 16px; }
	.fs18 { font-size: 18px; }
	.fs20 { font-size: 20px; }
	.fs22 { font-size: 22px; }
	.fs24 { font-size: 24px; }
	.fs26 { font-size: 26px; }
	.fs28 { font-size: 28px; }
	.fs30 { font-size: 30px; }
	.fs32 { font-size: 32px; }
	
</style>