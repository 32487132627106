<template>
	<div class="cardGroupContainer">
		<md-card md-with-hover v-for="card in cardData" v-bind:key="card.name">
			<md-ripple>
				<md-card-media>
					<img v-if="card.imageSource !== undefined" :src="'' + images[card.imageSource]" v-bind:alt="card.imageName" />
				</md-card-media>

				<div class="innerBox" v-if="card.title !== ''">
					<span class="md-title">{{ card.title }}</span>
				</div>
				<!--
				<md-card-area>
					<md-card-header>
						<span class="md-title">{{ card.title }}</span>
					</md-card-header>
				</md-card-area>
				-->
			</md-ripple>
		</md-card>
	</div>
</template>

<style lang="scss" scoped>
	.cardGroupContainer {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
	}
	.md-card {
		//width: 320px;
		height: 100%;
		margin: 1rem;
		//display: inline-block;
		vertical-align: top;
	}

	@media screen and (min-width: 600px) {
		.md-card {
			width: calc(50% - 2rem);
		}
	}

	@media screen and (min-width: 1000px) {
		.md-card {
			width: calc(33.3333% - 2rem);
		}
	}

	.innerBox{
		position:absolute;
		background:white;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		padding:35px;
		text-align:center;

		opacity: 0.5;
		transition: 0.2s;
		cursor: pointer;
	}
	.innerBox:hover {
		opacity: 0.8;
	}
</style>

<script>
	export default {
		name: "CardGroup",
		props: ['cardData', 'images'],
		data: () => ({
			///
		}),
        methods: {
			///
        },
	};
</script>